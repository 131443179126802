<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card>
          <!-- loading icon -->
          <div
            v-show="isLoading"
            id="misc"
          >
            <div class="page-title text-center justify-center px-5">
              <div class="misc-character d-flex justify-center">
                <v-img
                  max-width="100"
                  margin="100"
                  src="@/assets/images/misc/loading-spinner-dark.gif"
                ></v-img>
              </div>
            </div>
          </div>

          <v-col cols="12">
            <v-alert
              v-show="alert.show"
              :color="alert.color"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon :color="alert.color">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1 justify-right">
                    {{ alert.message }}
                  </p>
                  <a
                    align="right"
                    href="javascript:void(0)"
                    title="Hide this message"
                    :class="alert.class"
                    @click.prevent="hideAlert"
                  >
                    <span class="text-sm">{{ alert.callToAction }}</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-data-table
            v-show="!isLoading"
            :items="accounts"
            :headers="computedHeaders"
            :search="search"
            item-key="id"
            class="elevation-1"
            xxx-sort-by="type"
            :sort-by="['label']"
            :sort-desc="[true, false]"
            xxx-multi-sort
            xxx-group-by="type"
            xxx-show-group-by
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-icon class="text--primary me-3">
                    {{ icons.mdiMagnify }}
                  </v-icon>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="showUUIDs"
                  label="Detailed View"
                  class="mt-2"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  @click.prevent="createAccount"
                >
                  New  {{ title === 'Accounts' ? 'Account' : (title === 'Users' ? 'User' : 'Client') }}
                </v-btn>
              </v-toolbar>

              <v-dialog
                v-model="show_test_dialog"
                persistent
                max-width="660"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Client Test
                  </v-card-title>
                  <div
                    v-show="isLoadingTest"
                    class="page-title text-center justify-center px-5"
                  >
                    <div class="misc-character d-flex justify-center">
                      <v-img
                        max-width="100"
                        margin="100"
                        src="@/assets/images/misc/loading-spinner-dark.gif"
                      >
                      </v-img>
                    </div>
                  </div>
                  <v-card-text v-show="!isLoadingTest">
                    Below is the test url - you can open it from here or copy/paste it for use in an incognito browser session:<br /><br />
                    <strong>
                      <a
                        :href="test_url"
                        target="_blank"
                      >
                        {{ test_url }}
                      </a>
                    </strong><br /><br />
                    <div v-if="!client_with_config || !client_with_config.config || !client_with_config.config.response_types || !client_with_config.config.response_types.includes('id_token')">
                      Note: the client does not have the "id_token" as allowed response type configured, so you won't be seeing any ID Token payload on the test redirect page jwt.io.
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="primary"
                      color="green darken-1"
                      text
                      @click="closeTestDialog()"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h6">
                    Are you sure you want to delete this item?
                  </v-card-title>
                  <v-card-text v-if="is_admin">
                    You are about to delete an admin account. Make sure that there will be enough remaining admin users in order to administrate the tenant.
                  </v-card-text>
                  <!-- loading icon -->
                  <div
                    v-show="isLoadingAdminCheck"
                    class="page-title text-center justify-center px-5"
                    style="padding-bottom:10px"
                  >
                    <div class="misc-character d-flex justify-center">
                      <v-img
                        max-width="100"
                        margin="100"
                        src="@/assets/images/misc/loading-spinner-dark.gif"
                      ></v-img>
                    </div>
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :disabled="isLoadingAdminCheck"
                      color="error"
                      outlined
                      text
                      @click="deleteItemConfirm"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      outlined
                      text
                      @click="closeDelete"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                title="Details"
                @click="editItem(item)"
                :disabled="item.status === 'PENDING' || item.status === 'LOCKED'"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
              <v-icon
                v-show="canTest(item)"
                small
                class="mr-2"
                title="Test"
                @click="test(item)"
                :disabled="item.status === 'PENDING' || item.status === 'LOCKED'"
              >
                {{ icons.mdiMotionPlayOutline }}
              </v-icon>
              <v-icon
                small
                title="Delete Account"
                @click="deleteItem(item)"
                :disabled="item.status === 'PENDING' || item.status === 'LOCKED'"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <template v-slot:item.subtype="{ item }">
              <span>{{ subtypes[item.subtype] }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <span>{{ status[item.status] }}</span>
            </template>
            <template v-slot:item.created_at="{ item }">
              <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </template>
            <template v-slot:item.updated_at="{ item }">
              <span>{{ item.updated_at ? new Date(item.updated_at).toLocaleString() : '' }}</span>
            </template>
            <template v-slot:item.expires_at_epoch="{ item }">
              <span>{{ item.expires_at ? new Date(item.expires_at).toLocaleString() : '' }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as QuasrHelper from '@quasr-io/helper'
import {
  mdiMagnify,
  mdiPencilOutline, mdiDeleteOutline,
  mdiEyeOutline, mdiMotionPlayOutline,
} from '@mdi/js'
import * as pkceChallenge from 'pkce-challenge'
import * as RandExp from 'randexp'
import gql from 'graphql-tag'
import { getAccount, listAccounts } from '../../graphql/queries'
import { deleteAccount } from '../../graphql/mutations'
import store from '../../store'

/* eslint-disable */
export default {
  name: 'Accounts',

  /**
   * props
   */
  props: {
    subtype: { type: String, default: undefined },
    title: { type: String, default: 'Accounts' },
  },

  /**
   * data
   */
  data() {

    return {
      is_admin: false,
      titleSingular: this.title === 'Accounts' ? 'Account' : 'Client',
      search: '',
      test_url: '',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      client_with_config: {},
      dialog: false,
      show_test_dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Account ID',
          align: 'start',
          sortable: true,
          filterable: true,
          value: 'id',
        },
        {
          text: 'Account Label',
          sortable: true,
          filterable: true,
          value: 'label',
        },
        {
          text: 'Subtype',
          sortable: true,
          filterable: true,
          value: 'subtype',
        },
        {
          text: 'Status',
          sortable: true,
          filterable: true,
          value: 'status',
        },
        {
          text: 'Created at',
          sortable: true,
          filterable: true,
          value: 'created_at',
        },
        {
          text: 'Updated at',
          sortable: true,
          filterable: true,
          value: 'updated_at',
        },
        {
          text: 'Expires at',
          sortable: true,
          filterable: true,
          value: 'expires_at',
        },
        {
          text: 'Actions',
          sortable: false,
          filterable: false,
          value: 'actions',
        },
      ],
      accounts: [],
    }
  },

  /**
   * computed
   */
  computed: {
    /**
     * computedHeaders
     */
    computedHeaders () {
        return this.headers.filter((h) => (!['id', 'created_by', 'created_at', 'updated_at', 'updated_by'].includes(h.value)) || this.showUUIDs)
    }
  },

  /**
   * watch
   */
  watch: {
    showUUIDs() {
      localStorage.setItem('showUUIDs', this.showUUIDs)
    },
    subtype() {
      this.getAccounts()
    },
    tenant() {
      this.getAccounts()
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  /**
   * created
   */
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    await this.getAccounts()
    this.isLoading = false
    this.isLoadingTest = false
    this.isLoadingAdminCheck = false

    // display message if available
    if (store.message) {
      const msg = store.message.text.concat('')
      this.showAlert(msg, store.message.action, store.message.color)
      store.message = undefined
    }
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const showUUIDs = localStorage.getItem('showUUIDs') ? localStorage.getItem('showUUIDs') === 'true' : false
    const ADMIN_SCOPE = API_ENV === 'prod' ? 'https://api.quasr.io/scopes/admin' : `https://api-${API_ENV}.quasr.io/scopes/admin`
    const status = {
      'ENABLED': 'Enabled',
      'DISABLED': 'Disabled',
      'PENDING': 'Pending',
      'LOCKED': 'Locked',
    }
    const subtypes = {
      'user': 'User',
      'client': 'Client',
    }
    const isLoading = true
    const isLoadingTest = false
    const isLoadingAdminCheck = true

    // Mixpanel
    /*
    mixpanel.init('4ab4f868f268ab3290ef0863340eca20', { debug: false })
    mixpanel.track(`tenant:${QUASR_ENV.tenant_id}`)
    mixpanel.track('action:accounts.list')
    */

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      ADMIN_SCOPE,
      showUUIDs,
      isLoading,
      isLoadingAdminCheck,
      isLoadingTest,
      status,
      subtypes,
      loginStatus,
      icons: {
        mdiMagnify,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiMotionPlayOutline,
      },
    }
  },

  /**
   * methods
   */
  methods: {

    /**
     * selectAccount
     */
    async selectAccount(account) {
      this.$emit('select-account', account)
    },

    /**
     * closeTestDialog
     */
    closeTestDialog() {
      this.show_test_dialog = false
    },

    /**
     * test
     */
    async test(client) {
      const client_with_config = client
      this.isLoadingTest = true
      this.show_test_dialog = true

      this.client_with_config = client_with_config
      if (!client_with_config.config.grant_types.includes('authorization_code')) {
        this.isLoadingTest = false
        this.show_test_dialog = false
        this.showAlert('Only clients with Authorization Code Grant enabled can be tested from here.', 'OK', 'error')
        return
      }
      if (!client_with_config.config.response_types) {
        this.isLoadingTest = false
        this.show_test_dialog = false
        this.showAlert('Only clients with Allowed Response Types configured can be tested from here.', 'OK', 'error')
        return
      }

      // default redirect uri
      let redirect_uri = 'https://jwt.io'
      if (client_with_config?.config?.redirect_uris && client_with_config?.config?.redirect_uris.length > 0) {
        redirect_uri = client_with_config.config.redirect_uris[0]
      }

      // generate pkce + nonce + state
      const pkce = pkceChallenge.default()
      const nonce = new RandExp('[a-zA-Z0-9]{43}').gen()
      const state = new RandExp('[a-zA-Z0-9]{43}').gen()

      let sample_login_uri = this.API_ENV === 'prod' ?
          `https://${this.QUASR_ENV.tenant_id}.login.quasr.io` : `https://${this.QUASR_ENV.tenant_id}.login-${this.ENV}.quasr.io`
      if (this.ENV === 'loc') {
        sample_login_uri = `http://login-${this.ENV}.quasr.io:8081/l/${this.QUASR_ENV.tenant_id}/${client_with_config.id}`
      }
      sample_login_uri = sample_login_uri
        .concat(`?client_id=${client_with_config.id}`)
        .concat(`&response_type=${client_with_config.config.response_types ? client_with_config.config.response_types.join(' ') : ''}`)
        .concat(`&state=${state}`)
        .concat(`&nonce=${nonce}`)
        .concat(`&redirect_uri=${redirect_uri}`)
        .concat(`&scope=${client_with_config.config.scopes ? client_with_config.config.scopes.join(' ') : ''}`)
        .concat('&code_challenge=')
        .concat(pkce.code_challenge)
        .concat('&code_challenge_method=S256')
        .concat('&prompt=login')
        .concat('&mode=test')

      this.test_url = sample_login_uri
      this.isLoadingTest = false
    },

    /**
     * canTest
     */
    canTest(account) {
      return account.status === 'ENABLED' && account.subtype === 'client' && account.id !== this.QUASR_ENV.client_id && account.config?.grant_types?.includes('authorization_code') && account.config?.response_types
    },

    /**
     * getAccount
     */
    async getAccount(account_id) {
      const account = await store.getAppSyncClient().query({ query: gql(getAccount), variables: { id: account_id } })
      if (!account.data.getAccount.config) account.data.getAccount.config = {}
      if (!account.data.getAccount?.config?.client_secret) account.data.getAccount.config.client_secret = undefined
      return account.data.getAccount
    },

    /**
     * getAccounts
     */
    async getAccounts() {
      if (this.subtype) {
        const accounts = await store.getAppSyncClient().query({ query: gql(listAccounts), variables: { filter: { subtype: { eq: this.subtype } }, limit: 1000 } })
        this.accounts = accounts.data.listAccounts.items
      } else {
        const accounts = await store.getAppSyncClient().query({ query: gql(listAccounts), variables: { limit: 1000 } })
        this.accounts = accounts.data.listAccounts.items
      }
    },

    /**
     * showAlert
     */
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },

    /**
     * hideAlert
     */
    hideAlert() {
      this.alert.show = false
    },

    /**
     * close
     */
    close() {
      this.is_admin = false
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    /**
     * closeDelete
     */
    closeDelete() {
      this.is_admin = false
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    /**
     * viewItem
     */
    viewItem(item) {
      if (item.subtype === 'client') {
        this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/clients/${item.id}`)
      } else if (item.subtype === 'user') {
        this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/users/${item.id}`)
      } else {
        this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/accounts/${item.id}`)
      }
    },

    /**
     * editItem
     */
    editItem(item) {
      this.viewItem(item)
    },

    /**
     * deleteItem
     */
    async deleteItem(item) {
      this.isLoadingAdminCheck = true
      this.editedIndex = this.accounts.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true

      // admin check
      const account_to_delete = await this.getAccount(this.editedItem.id)
      this.is_admin = account_to_delete?.config?.permissions && account_to_delete?.config?.permissions.includes(this.ADMIN_SCOPE)
      this.isLoadingAdminCheck = false
    },

    /**
     * deleteItemConfirm
     */
    async deleteItemConfirm() {
      await this.deleteAccount(this.editedItem.id)
      this.closeDelete()
      this.is_admin = false
    },

    /**
     * deleteAccount
     */
    async deleteAccount(id) {
      this.isLoading = true
      const deletion = await store.getAppSyncClient().mutate({ mutation: gql(deleteAccount), variables: { input: { id: id } } })
      if (deletion.data) this.accounts.splice(this.editedIndex, 1)
      this.showAlert('Account successfully deleted.', 'OK', 'success')
      this.getAccounts()
      this.isLoading = false
    },

    /**
     * createAccount
     */
    async createAccount() {
      if (document.location.pathname.endsWith('/users')) { // this.subtype === 'user'
        this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/users/new`)
      } else if (document.location.pathname.endsWith('/clients')) { // this.subtype === 'client'
        this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/clients/new`)
      } else {
        this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/accounts/new`)
      }
    },
  },
}
/* eslint-enable */
</script>
